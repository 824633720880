import React from 'react';
import { faHeadphones, faVolumeLow, faVolumeHigh, faLock, faPowerOff, faHeadset, faEye } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux';
import { desktopIconClass } from '..';
import { lockWorkstation, putToSleep, setSoundDeviceTo, setVolumeTo, shutdown, wakeUp } from '../../../actions/api';
import Icon from './utils/Icon';

interface Props {
}
const Saturn: React.FC<Props> = () => {
    const dispatch: any = useDispatch();

    return (
        <>
            <div className="">

                <div>System tools</div>

                <button onClick={() => { dispatch(lockWorkstation("saturn")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faLock} inProgressId="lockWorkstation_saturn" />
                    </span>
                    <span className="title">
                        Lock
                    </span>
                </button >

                <button onClick={() => { dispatch(putToSleep("saturn")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faMoon} inProgressId="putToSleep_saturn" />
                    </span>
                    <span className="title">
                        Sleep
                    </span>
                </button>

                <button onClick={() => { dispatch(shutdown("saturn")) }} className={desktopIconClass}>
                    <span className="icon text-theme-red">
                        <Icon icon={faPowerOff} inProgressId="shutdown_saturn" />
                    </span>
                    <span className="title">
                        Shutdown
                    </span>
                </button>

                <button onClick={() => { dispatch(wakeUp("saturn")) }} className={desktopIconClass}>
                    <span className="icon" style={{ color: "#1E88E5" }}>
                        <Icon icon={faEye} inProgressId="wakeUp_saturn" />
                    </span>
                    <span className="title">
                        Wake up
                    </span>
                </button>

            </div>

            <div className="">

                <div>Audio output</div>

                <button onClick={() => { dispatch(setSoundDeviceTo("saturn", "HEADPHONES")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faHeadphones} inProgressId="setSoundDeviceTo_saturn_HEADPHONES" />
                    </span>
                    <span className="title">
                        Headphones
                    </span>
                </button>

                <button onClick={() => { dispatch(setSoundDeviceTo("saturn", "HEADPHONES TOP")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faHeadset} inProgressId="setSoundDeviceTo_saturn_HEADPHONES TOP" />
                    </span>
                    <span className="title">
                        Headset
                    </span>
                </button>

                <button onClick={() => { dispatch(setSoundDeviceTo("saturn", "OPTICAL OUT")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeHigh} inProgressId="setSoundDeviceTo_saturn_OPTICAL OUT" />
                    </span>
                    <span className="title">
                        Optical Out
                    </span>
                </button>

            </div>

            <div>
                <div>Volume</div>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "1")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeLow} inProgressId="setVolumeTo_saturn_1" />
                    </span>
                    <span className="title">
                        Volume 1 %
                    </span>
                </button>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "2")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeLow} inProgressId="setVolumeTo_saturn_2" />
                    </span>
                    <span className="title">
                        Volume 2 %
                    </span>
                </button>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "10")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeLow} inProgressId="setVolumeTo_saturn_10" />
                    </span>
                    <span className="title">
                        Volume 10 %
                    </span>
                </button>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "20")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeLow} inProgressId="setVolumeTo_saturn_20" />
                    </span>
                    <span className="title">
                        Volume 20 %
                    </span>
                </button>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "50")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeLow} inProgressId="setVolumeTo_saturn_50" />
                    </span>
                    <span className="title">
                        Volume 50 %
                    </span>
                </button>

                <button onClick={() => { dispatch(setVolumeTo("saturn", "100")) }} className={desktopIconClass}>
                    <span className="icon">
                        <Icon icon={faVolumeHigh} inProgressId="setVolumeTo_saturn_100" />
                    </span>
                    <span className="title">
                        Volume 100 %
                    </span>
                </button>

            </div>
        </>
    );
}

export default Saturn;